import { Injectable } from "@angular/core";
import { Preferences } from '@capacitor/preferences';


@Injectable({
    providedIn: "root",
})
export class StorageUserService {
    constructor() { }

    async setUser(data:any) {
        await Preferences.set({
            key: "user-login",
            value: JSON.stringify(data),
        });
    }

    async getUser() {
        const response: any = await Preferences.get({ key: "user-login" });
        return JSON.parse(response.value);
    }

    async removeUser() {
        await Preferences.remove({ key: "user-login" });
        await Preferences.remove({ key: "tokens" });
    }


    async setTokens(data:any) {
        await Preferences.set({
            key: "tokens",
            value: JSON.stringify(data),
        });
    }

    async getTokens() {
        const response: any = await Preferences.get({ key: "tokens" });
        try {
            return JSON.parse(response.value);
        } catch (error) {
            return null;
        }
    }


}
